/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardCodabow from "views/HomePage/Sections/codabow.png";
import cardAurora from "views/HomePage/Sections/aurora.png";
import cardWatlow from "views/HomePage/Sections/watlow.png";
import cardWinona from "views/HomePage/Sections/winona.png";

const useStyles = makeStyles(projectsStyle);

export default function SectionWork({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 3 START */}
      <div
        className={
          classes.projects + " " + classes.sectionDark + " " + classes.projects3
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <Muted>
                <h4>MY WORK</h4>
              </Muted>
              <h2 className={classes.title}>Some of My Projects</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card plain className={classes.card2}>
                <a href="https://codabow.com">
                  <CardHeader plain image>
                    <img src={cardCodabow} alt="..." />
                  </CardHeader>
                </a>
                <CardBody>
                  <h6 className={classes.cardCategory}>FULL STACK</h6>
                  <a href="https://codabow.com">
                    <h4 className={classes.cardTitle}>CODABOW</h4>
                  </a>
                  <p className={classes.cardDescription}>
                    Full stack development with analytical graphs and dynamic
                    forms.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card plain className={classes.card2}>
                <a href="https://www.aurorascheduling.com/">
                  <CardHeader plain image>
                    <img src={cardAurora} alt="..." />
                  </CardHeader>
                </a>
                <CardBody>
                  <h6 className={classes.cardCategory}>FRONT END</h6>
                  <a href="https://www.aurorascheduling.com/">
                    <h4 className={classes.cardTitle}>AURORA</h4>
                  </a>
                  <p className={classes.cardDescription}>
                    Front end work with dynamic svg scaling and styling.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card plain className={classes.card2}>
                <a href="https://www.watlow.com">
                  <CardHeader plain image>
                    <img src={cardWatlow} alt="..." />
                  </CardHeader>
                </a>
                <CardBody>
                  <h6 className={classes.cardCategory}>SOFTWARE ENGINEER</h6>
                  <a href="https://www.watlow.com">
                    <h4 className={classes.cardTitle}>WATLOW</h4>
                  </a>
                  <p className={classes.cardDescription}>
                    Software development with .NET applications and SQL database
                    backend.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card plain className={classes.card2}>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  <CardHeader plain image>
                    <img src={cardWinona} alt="..." />
                  </CardHeader>
                </a>
                <CardBody>
                  <h6 className={classes.cardCategory}>AUTOMATED TESTING</h6>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    <h4 className={classes.cardTitle}>
                      WINONA STATE UNIVERSITY
                    </h4>
                  </a>
                  <p className={classes.cardDescription}>
                    Construct automated test using Telerik Test Studio for WPF
                    applications.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 3 END */}
    </div>
  );
}
