import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import RouterIcon from "@material-ui/icons/Router";
import StorageIcon from "@material-ui/icons/Storage";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "assets/img/sections/iphone.png";
import iphone2 from "views/HomePage/Sections/stretch.png";
import bg9 from "assets/img/bg9.jpg";

const useStyles = makeStyles(featuresStyle);

export default function SectionTechnologies({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Hello!</h2>
              <h3 className={classes.description}>
                My name is Barett. I am a software engineer and full stack
                developer. I enjoy both front end and backend development. If I
                am not working on an amazing work project, I could be messing
                around with new technologies or playing games. If you are
                looking for a developer don't be afraid to{" "}
                <a href="/#contact">contact me</a>.
              </h3>
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}
        {/* Feature 2 START */}
        {/* Feature 2 END */}
        {/* Feature 3 START */}
        {/* Feature 3 END */}
        {/* Feature 4 START */}
        <div className={classes.features4}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Web Technologies</h2>
              <h3 className={classes.description}>
                Proudly develop full stack applications. My preference is on the
                MERN (MongoDB, Express.js, React.js, Node.js) stack, but have
                also worked with MEAN (Angular) and other non-node stacks such
                as .NET and laravel.
              </h3>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAuto}>
              <InfoArea
                icon={Code}
                title="Front End"
                description="Fashionably creating dynamic front end user interfaces and expereinces in React.js, React Native, HTML5, CSS, and Angular"
                iconColor="info"
              />
              <InfoArea
                icon={RouterIcon}
                title="Backend"
                description="Effeciently managing backend components with Node.js, Express.js, and PHP"
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <div className={classes.phoneContainer}>
                <img src={iphone2} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAuto}>
              <InfoArea
                icon={StorageIcon}
                title="Database"
                description="Designed and implemented scalable database solutions with MongoDB, SQLite, MySQL, and Microsoft SQL Server"
                iconColor="primary"
              />
              <InfoArea
                icon={LanguageIcon}
                title="Languages"
                description="Experience developing full stack applications with Javascript, HTML, CSS, PHP, SQL, and C#"
                iconColor="success"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
