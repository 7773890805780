/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionTechnologies from "views/HomePage/Sections/SectionTechnologies.js";
import SectionWork from "views/HomePage/Sections/SectionWork.js";
import SectionAbout from "views/HomePage/Sections/SectionAbout.js";
import SectionContact from "views/HomePage/Sections/SectionContact";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import logoHorizontal from "assets/img/bjlogo/whitelogohor.png";

const logoStyle = {
  width: "100%",
};

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
  });

  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Barett Jones"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/bjlogo/galaxy.jpg").default}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h3>
                  <img
                    src={logoHorizontal}
                    alt="Barett Logo"
                    style={logoStyle}
                  />
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionTechnologies id="home" />
        <SectionWork id="work" />
        <SectionAbout id="about" />
        <SectionContact id="contact" />
      </div>

      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a href="#home" className={classes.footerBrand}>
                Barett Jones
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="#home" className={classes.block}>
                    Home
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#work" className={classes.block}>
                    Work
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#about" className={classes.block}>
                    About
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#contact" className={classes.block}>
                    Contact Me
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    justIcon
                    simple
                    color="github"
                    className={classes.margin5}
                    onClick={() =>
                      (window.location.href = "https://github.com/bgjones15")
                    }
                  >
                    <i className={"fab fa-git"} />
                  </Button>
                </li>
                <li>
                  <Button
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.margin5}
                    onClick={() =>
                      (window.location.href =
                        "https://www.linkedin.com/in/barett-jones-a4499a162")
                    }
                  >
                    <i className={classes.socials + " fab fa-linkedin"} />
                  </Button>
                </li>
                <li>
                  <Button
                    justIcon
                    simple
                    color="google"
                    className={classes.margin5}
                    onClick={() =>
                      (window.location.href = window.location.href =
                        "mailto:barettj22@gmail.com")
                    }
                  >
                    <i className={classes.socials + " fab fa-google"} />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
